<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    :max-width="dialogWidth"
    persistent
    overlay-opacity="0.97"
    @keydown.esc="cancel"
    eager
  >
    <NotificationContainer />
    <v-card
      v-touch:swipe.left="cancel"
      class="d-flex flex-column noscroll charcoal "
      tile
    >
      <BaseCloseDialogHeader heading="Add Supplement" @cancel="cancel" />

      <BaseLoading :loaded="loaded" label="Searching Supplements" />
      <v-card-actions class="pa-0 charcoalTileMenu rounded mx-2">
        <v-datetime-picker
          dark
          class="silver--text mr-4"
          label="Date & Time"
          v-model="datetime"
          :text-field-props="textFieldProps"
          :date-picker-props="dateProps"
          :time-picker-props="timeProps"
          timeFormat="HH:mm:ss"
          dateFormat="yyyy-MM-dd"
        >
          <template v-slot:dateIcon>
            <v-icon>mdi-calendar</v-icon>
          </template>
          <template v-slot:timeIcon>
            <v-icon>mdi-clock</v-icon>
          </template>
        </v-datetime-picker>

        <v-spacer />
        <BaseActionButton
          dark
          icon="mdi-content-save-all-outline"
          text
          label="Save All"
          color="progressActive"
          @clickedThis="saveSupplements()"
          :disabled="!topSelected && !searchSelected"
        />
      </v-card-actions>
      <v-card-actions class="py-0">
        <v-spacer />
        <v-switch
          dark
          color="progressActive"
          v-model="useAll"
          label="Show Full Supplement List?"
        />
      </v-card-actions>
      <template v-if="topSupplementsList.length > 0 && !useAll">
        <v-card-title
          :class="textSize + '   mt-n2 pl-2 pb-0 progressActive--text'"
        >
          Last Used
          <v-spacer />
        </v-card-title>
        <v-sheet
          rounded
          v-for="(supplement, index) in topSupplementsList"
          :key="index"
          class="ma-2 mt-0 pa-2 charcoal charcoalTileMenu"
        >
          <v-card-actions class="silver--text pa-0">
            <span :class="textSize + ' paper--text'">
              {{ supplement.supplement }}
            </span>
            <v-spacer />

            <span class="paper--text" v-if="supplement.rda > 0">
              RDA/AI: {{ supplement.rda }} {{ supplement.unit }}
            </span>
          </v-card-actions>

          <v-row class="mt-2">
            <v-col cols="5">
              <v-text-field
                dense
                dark
                color="progressActive"
                :label="supplement.unit"
                ref="quantity"
                v-model="supplement.qty"
                class="enlarged-input"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-weight"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-spacer />
            <v-icon
              class="mr-2"
              large
              :color="supplement.selected ? 'progressActive' : 'paper'"
              @click="supplement.selected = !supplement.selected"
              >Search
              {{
                supplement.selected
                  ? 'mdi-checkbox-outline'
                  : 'mdi-checkbox-blank-outline'
              }}
            </v-icon>
          </v-row>
          <v-card-text class="caption  silver--text pa-0">
            Measured In: {{ supplement.unit_label }}
            <v-spacer />
            Usual serving size: {{ supplement.serving_size }}
          </v-card-text>
        </v-sheet>
      </template>
      <v-card-text
        v-if="!topSupplementsList.length > 0 || useAll"
        class="pa-0 charcoal"
      >
        <template v-if="loaded">
          <v-card-actions class="pt-0 mt-2 mb-4">
            <BaseSearch
              dark
              :items="supplementsList"
              :label="supplementsList.length + ' supplements'"
              what="supplement"
              :limit="1"
              @search_results="setResults"
            />
          </v-card-actions>
          <template v-if="searchList.length > 0 && loaded">
            <v-sheet
              rounded
              v-for="(supplement, index) in searchList"
              :key="'sl ' + index"
              class="ma-2 mt-0 pa-2 charcoal charcoalTileMenu"
            >
              <v-card-actions class="silver--text pa-0">
                <span :class="textSize + ' paper--text'">
                  {{ supplement.supplement }}
                </span>
                <v-spacer />

                <span class="paper--text" v-if="supplement.rda > 0">
                  RDA/AI: {{ supplement.rda }} {{ supplement.unit }}
                </span>
              </v-card-actions>

              <v-row class="mt-2">
                <v-col cols="5">
                  <v-text-field
                    dense
                    dark
                    color="progressActive"
                    :label="supplement.unit"
                    ref="quantity"
                    v-model="supplement.qty"
                    class="enlarged-input"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-spacer />
                <v-icon
                  class="mr-2"
                  large
                  :color="supplement.selected ? 'progressActive' : 'paper'"
                  @click="supplement.selected = !supplement.selected"
                >
                  {{
                    supplement.selected
                      ? 'mdi-checkbox-outline'
                      : 'mdi-checkbox-blank-outline'
                  }}
                </v-icon>
              </v-row>
              <v-card-text class="caption  silver--text pa-0">
                Measured In: {{ supplement.unit_label }}
                <v-spacer />
                Usual serving size: {{ supplement.serving_size }}
              </v-card-text>
            </v-sheet>
          </template>
        </template>
      </v-card-text>

      <v-spacer />

      <v-card-actions class="pl-0 mt-2 pr-1 ">
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import { isMobile } from 'mobile-device-detect'

const NotificationContainer = () =>
  import(/* webpackPrefetch: true */ '@/components/NotificationContainer.vue')

export default {
  components: { NotificationContainer },
  name: 'AddSupplements',
  mixins: [validationMixin, util],
  data() {
    return {
      mobile: isMobile,
      textFieldProps: {
        dark: true,
        prependIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      switchNum: 0,
      fraction: 1,
      loaded: false,
      useAll: false,
      isNow: true,
      noresults: false,
      search: '',
      selectedUnit: null,
      nutrients: null,
      qty: 1,
      supplementsNum: 0,
      supplementsList: [],
      topSupplementsList: [],
      searchList: [],
      datetime: null
    }
  },
  beforeMount() {
    this.reset()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('message', this.receiveMessage)
    })
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    day: {
      type: String
    }
  },
  computed: {
    topSelected() {
      const found = this.topSupplementsList.find(
        element => element.selected && element.qty > 0
      )
      return found
    },
    searchSelected() {
      const found = this.searchList.find(
        element => element.selected && element.qty > 0
      )
      return found
    },
    ...appConfig
  },
  watch: {
    show(val) {
      this.$store.dispatch('session/toggleModal', val)
      this.reset()
    }
  },

  methods: {
    handleSelection(supplement) {
      supplement.selected = !supplement.slected
    },
    setResults(value) {
      this.searchList = value
    },
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showNutrientsDialog) this.showNutrientsDialog = false
        else this.cancel()
      }
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },

    reset() {
      if (this.day != null) {
        this.datetime = this.day
      }
      this.loaded = true
      this.useAll = false
      this.search = ''
      this.searchList = []
      this.loadTopSupplements()
      this.loadSupplements()
    },

    saveSupplements() {
      let selection = null
      if (this.useAll) {
        selection = this.searchList.filter(el => {
          return el.qty > 0 && el.selected
        })
      } else {
        selection = this.topSupplementsList.filter(el => {
          return el.qty > 0 && el.selected
        })
      }
      let dateObj = new Date(this.datetime)

      return axios
        .post(this.baseURL + '/nutrition/supplements', {
          supplements: selection,
          when: this.formatDateTime(dateObj)
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Supplement(s) succesfully added.')
            this.$emit('done')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadSupplements() {
      this.loaded = false
      return axios
        .get(this.baseURL + '/nutrition/supplements', {})
        .then(response => {
          if (response.status == 200) {
            response.data.data.forEach(item => {
              item.qty = 0
              item.selected = false
            })
            this.supplementsList = response.data.data
            this.supplementsNum = this.supplementsList.length

            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadTopSupplements() {
      this.loaded = false
      return axios
        .get(this.baseURL + '/nutrition/supplements/top', {})
        .then(response => {
          if (response.status == 200) {
            response.data.data.forEach(el => {
              el.selected = false
            })
            this.topSupplementsList = response.data.data

            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
<style></style>
